<mat-card>
  <mat-card-header>
    <mat-card-title>Privacy Riskchanger</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      Tijdens het invullen van de vragenlijsten is het niet nodig om toestemming te geven dat jouw gegevens opgeslagen of
      gedeeld worden. Dit komt omdat wij geen persoonlijke gegevens van je vragen en opslaan. Door het gebruik van een QR-code
      vragen we aan bijvoorbeeld studenten, docenten, ondersteunend onderwijzend personeel en beleidsmedewerkers geen
      emailadres. Alleen de aanvragers van een QR code moeten een emailadres opgeven en die wordt encrypt opgeslagen. Daarvoor
      is geen toestemming nodig van jou als gebruiker van de vragenlijsten. Alle antwoorden worden anoniem opgeslagen.
    </p>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>