export default class AggregateReport {
  organizationId?: string;
  surveyId?: string;
  emailid?: string;
  emailSubject?: string;
  emailText?: string;
  reportUrl?: string;
  result?: string;
  payload?: string;
  createdDate?: number;
  updatedDate?: number;
}