// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const firebase = {
  config: getConfig()
};
function getConfig() {
  let config;
  let request = new XMLHttpRequest();
  try {
    request.open('GET', 'rest/firebaseconfig', false);  // `false` makes the request synchronous
      request.send(null);

      if (request.status === 200) {
          const res = JSON.parse(request.responseText)
          if(res?.envInfo){
            config = res.envInfo;
            console.log("response is ::"+res.envInfo.projectId);
          }
      }
      return config;
  } catch (e) {
      console.error('environment:getConfig: unable to get api key : ', e);
  }

  return config;
}