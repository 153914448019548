<form [formGroup]="authForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Welkom bij de 360° vragenlijsten, vul jouw mailadres op school in om verder te gaan.</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Jouw mailadres</mat-label>
            <input matInput formControlName="emailId">
            <mat-error *ngIf="authForm.controls['emailId'].hasError('email')">
              Vul een geldig emailadres in
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!authForm.valid">
        <mat-icon>login</mat-icon>
        Volgende
      </button>
    </mat-card-actions>
  </mat-card>
</form>
