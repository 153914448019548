import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {beonderConfig} from './beonder-info';

@Injectable({
  providedIn: 'root'
})
export class BeonderApiService {

  config = beonderConfig.config;
  shareFormApi: string = this.config.shareForm;
  reportsApi: string  = this.config.reportsUrl;
  startSurveyApi: string = this.config.startUrl;

  constructor() {}

  createBeonderSlug(requestBody: string): Promise<string> {
    const URL = this.shareFormApi;
    const promise = new Promise<string>((resolve, reject) => {
      fetch(URL, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        body: requestBody,
      }).then(response => {
        return response.json();
      }).then(response => {
        if (response.success === true) {
          if (response.url) {
            const slug = response.url;
            resolve(slug);
          }
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
    });
    return promise;
  }

  createAggregateReportURL(requestBody: string): Promise<string> {
    const URL = this.reportsApi;
    const promise = new Promise<string>((resolve, reject) => {
      fetch(URL, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        body: requestBody,
      }).then(response => {
        return response.json();
      }).then(response => {
        if (response.success === true) {
          if (response.url) {
            resolve(response);
          }
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
    });
    return promise;
  }

  goToBeonderSurvey(beonderSlug: string) {
    self.location.href = this.startSurveyApi + beonderSlug;
  }

  getEncodedData(slug: string, title: string, location: string, assigned: string, permission: number) {
    return `slug=${slug}&customer_title=${encodeURIComponent(title)}&location={"city": "${encodeURIComponent(location)}", "address": "${encodeURIComponent(location)}", "title": "${encodeURIComponent(location)}"}&assigned=${encodeURIComponent(assigned)}&data={"Toestemming.Akkoord": ${permission}}`;
  }
}
