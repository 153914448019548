import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AggregateReportsComponent } from './pages/aggregate-reports/aggregate-reports.component';
import { AuthComponent } from './pages/auth/auth.component';
import { CreateSurveyComponent } from './pages/create-survey/create-survey.component';
import { HomeComponent } from './pages/home/home.component';
import { SelfEvaluationComponent } from './pages/self-evaluation/self-evaluation.component';
import { OverComponent } from './pages/over/over.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

const routes: Routes = [
  { path: 'auth', component: AuthComponent },
  { path: 'home', component: HomeComponent },
  { path: 'evaluation', component: SelfEvaluationComponent },
  { path: 'feedback', component: CreateSurveyComponent },
  { path: 'reports', component: AggregateReportsComponent },
  { path: 'over', component: OverComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: '', redirectTo: '/auth', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
