import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import Masterdata from '../types/masterdata';

@Injectable({
  providedIn: 'root'
})
export class MasterdataService {
  private dbPath = '/MasterData';
  constructor(private db: AngularFirestore) { }

  filterByCompanyAndSurveyAndLevel(orgId: string, surveyId: string, level: number): Observable<Masterdata[]> {
    return this.db.collection(
      this.dbPath, ref => ref.where('organizationId', '==', orgId).where('surveyId', '==', surveyId).where('level', '==', level)
    ).valueChanges() as unknown as Observable<any>;
  }

  filterByCompanyAndSurveyAndParent(orgId: string, surveyId: string, parentEntityId: string): Observable<Masterdata[]> {
    return this.db.collection(
      this.dbPath, ref => ref.where('organizationId', '==', orgId).where('surveyId', '==', surveyId).where('parentEntityId', '==', parentEntityId)
    ).valueChanges() as unknown as Observable<any>;
  }
}
