<mat-card class="home-card">
  <mat-card-header>
    <mat-card-title>360° Vragenlijsten</mat-card-title>
    <!-- <mat-card-subtitle>Riskchanger heeft een unieke softwaretool ontwikkeld voor 360° vragenlijsten</mat-card-subtitle> -->
  </mat-card-header>
  <img mat-card-image src="../../assets/images/home.jpg" alt="Photo of home page">
  <mat-card-content>
    <p>
      Riskchanger heeft een unieke softwaretool ontwikkeld voor 360° vragenlijsten. Vul een vragenlijst individueel of met een
      team in. Kies voor het uitzetten van een 360° vragenlijst om een of meer complete doelgroep(en) te bevragen. Bekijk het
      verzamelrapport om de resultaten hiervan in te zien.
    </p>
  </mat-card-content>
  <mat-card-actions>
    <div routerLink="../evaluation">
      <button mat-button color="accent">
        <mat-icon>fact_check</mat-icon>
        <span>Vul een 360° vragenlijst in</span>
      </button>
    </div>
    <div routerLink="../feedback">
      <button mat-button color="accent">
        <mat-icon>qr_code</mat-icon>
        <span>Ontvang een QR-code voor het uitzetten van de 360° vragenlijst</span>
      </button>
    </div>
    <div routerLink="../reports">
      <button mat-button color="accent">
        <mat-icon>cloud_download</mat-icon> Bekijk het verzamelrapport van de 360° vragenlijst
      </button>
    </div>
  </mat-card-actions>
</mat-card>
