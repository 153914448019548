import { Component } from '@angular/core';
import { DocumentReference } from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import AggregateReport from 'src/app/models/aggregate-report';
import { AggregateReportService } from 'src/app/services/aggregate-report.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BeonderApiService } from 'src/app/services/beonder-api.service';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { OrganizationSurveyService } from 'src/app/services/organization-survey.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { RestService } from 'src/app/services/rest.service';
import { SurveyMetadataService } from 'src/app/services/survey-metadata.service';
import { UtilService } from 'src/app/services/util.service';
import Masterdata from 'src/app/types/masterdata';
import Organization from 'src/app/types/organization';
import OrganizationSurvey from 'src/app/types/organization-survey';
import SurveyMetadata from 'src/app/types/survey-metadata';
import SurveyTarget from 'src/app/types/survey-targets';

@Component({
  selector: 'app-aggregate-reports',
  templateUrl: './aggregate-reports.component.html',
  styleUrls: ['./aggregate-reports.component.scss']
})
export class AggregateReportsComponent {
  organization?: Organization;
  surveys?: OrganizationSurvey[];
  selectedSurvey?: OrganizationSurvey;
  selectedSurveyId?: string;
  surveyEntities?: SurveyMetadata[];
  disableSubmitBtn: boolean = false;

  feedbackForm = this.fb.group({
    email: [null, Validators.compose([Validators.required, Validators.email])],
    survey: [null, Validators.required],
    target: [null, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private utilService: UtilService,
    private orgService: OrganizationService,
    private orgSurveyService: OrganizationSurveyService,
    private surveyMetadataService: SurveyMetadataService,
    private masterdataService: MasterdataService,
    private beonderService: BeonderApiService,
    private reportService: AggregateReportService,
    private restService: RestService,
    private authService : AuthenticationService
  ) {
    this.feedbackForm.controls.survey.disable();
    this.feedbackForm.controls.target.disable();
  }

  onEmailUpdate(event: any) {
    this.feedbackForm.controls.survey.enable();
    if(this.feedbackForm.controls.email.valid) {
      this.validateDomainAndGetOrganization(this.feedbackForm.controls.email.value);
    }
  }

  validateDomainAndGetOrganization(emailId: string) {
    this.orgService.getByDomain(this.utilService.getDomainName(emailId))
      .subscribe((data: Organization[]) => {
        if(!data?.length) {
          // domain not linked with an organization
          this.snackbar.open('Emaildomein is niet bekend in het systeem. Zorg dat je een emailadres gebruikt wat gekoppeld is aan het domein van de school.', 'close');
          return;
        }

        this.organization = data[0];
        this.getOrganizationSurveys(this.organization);
      });
  }

  getOrganizationSurveys(org: Organization) {
    this.orgSurveyService.getByOrganizationId(org.sid)
      .subscribe((data: OrganizationSurvey[]) => {
        this.surveys = data;
      });
  }

  onSurveyChange() {
    const survey: OrganizationSurvey = this.feedbackForm.controls.survey.value;

    // if user have an already selected survey, we have to clear the existing form controls
    if(this.selectedSurveyId && survey.surveyId !== this.selectedSurveyId) {
      this.clearExistingSelectedSurveyFormControls(this.surveyEntities ?? []);
    }
    this.selectedSurveyId = survey.surveyId;
    this.selectedSurvey = survey;
    this.feedbackForm.controls.target.enable();
    this.surveyMetadataService.getBySurveyId(survey.surveyId)
      .subscribe((data: SurveyMetadata[]) => {
        if(this.organization) {
          const sortedEntities = this.surveyMetadataService.sortSurveyEntities(data);
          sortedEntities.forEach(entity => {
            const control = new FormControl(null, Validators.required);
            control.disable();
            this.feedbackForm.addControl(entity.entityName, control);
          });
          this.surveyEntities = sortedEntities;

          // get main entity data
          this.getMainEntityData();
        }
      });
  }

  clearExistingSelectedSurveyFormControls(entities: SurveyMetadata[]) {
    entities?.forEach((entity: SurveyMetadata) => {
      this.feedbackForm.removeControl(entity.entityName);
    });
  }

  getMainEntityData() {
    if (!(this.organization && this.surveyEntities)) {
      return;
    }
    const mainEntity: SurveyMetadata = this.surveyEntities[0];

    this.feedbackForm.controls[mainEntity.entityName].enable();
    this.masterdataService.filterByCompanyAndSurveyAndLevel(this.organization?.sid, mainEntity.surveyId, 1)
      .subscribe((data: Masterdata[]) => {
        mainEntity.options = data;
      });
  }

  onEntityChange(parentEntity: SurveyMetadata) {
    if (!(this.organization && this.surveyEntities)) {
      return;
    }
    const { level } = parentEntity;
    const childEntity: SurveyMetadata = this.surveyEntities[level]; // surveyEntties is sorted array and level starts with 1
    if (!childEntity) {
      // means the selected child entity was the last sub entity
      return;
    }
    const parentEntityMasterdata: Masterdata = this.feedbackForm.controls[parentEntity.entityName].value;

    this.feedbackForm.controls[childEntity.entityName].enable();
    this.masterdataService.filterByCompanyAndSurveyAndParent(this.organization?.sid, childEntity.surveyId, parentEntityMasterdata.sid)
      .subscribe((data: Masterdata[]) => {
        childEntity.options = data;
      });
  }

  onSubmit(): void {
    if (!this.surveyEntities) {
      return;
    }
    const slugs: string[] = this.feedbackForm.controls.target.value;
    const mainEntityName: string = this.surveyEntities[0].entityName;
    const subEntityName: string = this.surveyEntities[1].entityName;
    const mainEntity: Masterdata = this.feedbackForm.controls[mainEntityName].value;
    const subEntity: Masterdata = this.feedbackForm.controls[subEntityName].value;
    let entityNames: string[] = [];

    this.disableSubmitBtn = true;
    this.snackbar.open("We zijn je verzoek aan het verwerken.", "close");

    this.surveyEntities.forEach(entity => {
      const staticControls = ['email', 'survey', 'target', 'privacy', 'endDate'];
      if (!staticControls.includes(entity.entityName)) {
        const masterdata:Masterdata = this.feedbackForm.controls[entity.entityName].value;
        entityNames.push(masterdata.entityValue);
      }
    });

    // #RCD-71 refers to send separate emails for each target group
    slugs.forEach((slug: string) => {
      const reqBody = this.getEncodedData(slug, mainEntity.entityValue, subEntity.entityValue, entityNames.join('/'))
      this.beonderService.createAggregateReportURL(reqBody)
        .then((response: any) => {
          const targetGroup: SurveyTarget = this.getTargetGroup(slug);
          const reportObj = new AggregateReport();
          reportObj.organizationId = this.organization?.sid;
          reportObj.surveyId = this.selectedSurveyId;
          reportObj.emailid = this.feedbackForm.controls.email.value;
          reportObj.reportUrl = response.url;
          reportObj.result = response.forms_finished;
          reportObj.payload = reqBody;
          reportObj.emailSubject = `Rapportage ${this.selectedSurvey?.surveyName} - ${targetGroup.targetName}`;
          reportObj.emailText = targetGroup.emailTextReport;

          this.reportService.create(reportObj)
            .then((response: DocumentReference) => {
              reportObj.createdDate = new Date().getTime();
              reportObj.updatedDate = new Date().getTime()

              this.restService.post('feedbacks/emailReport', JSON.stringify(reportObj))
                .then(response => {
                  this.snackbar.open("Verzamelrapport is gegenereerd en verzonden naar jouw emailadres.", "close");
                  console.log(response);
                });
            });

        }, response => {
          console.log(response);
          this.snackbar.open("Helaas hebben we voor deze vragenlijst nog geen responses ontvangen.", "close");
        })
        .catch(error => {
          console.log(error);
          this.snackbar.open("Er is een probleem opgetreden bij het aanmaken van de vragenlijst. Neem contact op met helpdesk@riskchanger.nl.", "close");
        });
    });
  }

  getEncodedData(slug: string, title: string, location: string, assigned: string) {
    return `slug=${slug}&customer_title=${encodeURIComponent(title)}&location={"city": "${encodeURIComponent(location)}", "address": "${encodeURIComponent(location)}", "title": "${encodeURIComponent(location)}"}&assigned=${encodeURIComponent(assigned)}`;
  }

  getTargetGroup(slug: string): SurveyTarget {
    const filteredTarget:  SurveyTarget[] = this.selectedSurvey?.targets.filter(targetGroup => targetGroup.slug === slug) || [];
    return filteredTarget[0];
  }

  getTargetEmailText(slug: string): string {
    const filteredTarget:  SurveyTarget[] = this.selectedSurvey?.targets.filter(targetGroup => targetGroup.slug === slug) || [];
    return filteredTarget[0].emailTextReport || '';
  }

  ngOnInit(){
    this.authService.signIn();
  }
}
