import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import Organization from 'src/app/types/organization';

import { OrganizationService } from 'src/app/services/organization.service';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  authForm = this.fb.group({
    emailId: [null, Validators.compose([Validators.required, Validators.email])],
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackbar: MatSnackBar,
    private utilService: UtilService,
    private authService: AuthService,
    private orgService: OrganizationService,
  ) {}

  onSubmit(): void {
    const emailId: string = this.authForm.controls.emailId.value; 
    this.orgService.getByDomain(this.utilService.getDomainName(emailId))
      .subscribe((data: Organization[]) => {
        if(!data?.length) {
          // domain not linked with an organization
          this.snackbar.open('Emaildomein is niet bekend in het systeem. Zorg dat je een emailadres gebruikt wat gekoppeld is aan het domein van de school.', 'close');
          return;
        }
        
        this.authService.setEmailId(emailId);
        this.authService.setOrganization(data[0]);
        this.router.navigate(['home']);
      });
  }
}
