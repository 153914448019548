import { Timestamp } from 'firebase/firestore';

export default class TargetSurvey {
  organizationId?: string;
  surveyId?: string;
  surveyName?: string;
  targetName?: string;
  emailid?: string;
  emailText?: string;
  emailTextDeadLine?: string;
  emailTextReport?: string;
  slug?: string;
  payload?: string;
  reportPayload?: string;
  endDate?: number;
  isActive?: boolean;
  createdDate?: Timestamp;
  updatedDate?: Timestamp;
}