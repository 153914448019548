<form [formGroup]="evaluationForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>360° Vragenlijst invullen</mat-card-title>
    </mat-card-header>
    <img mat-card-image src="../../assets/images/evaluation.png" alt="Photo of a self evaluation page">
    <mat-card-content>
      <p>
        Op deze pagina kun je op basis van je eigen emailadres van je organisatie een vragenlijst selecteren en je eigen
        criteria selecteren. Daarna kun je eenmalig de vragen beantwoorden, een rapport downloaden en de resultaten bekijken.
      </p>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput 
              placeholder="Jouw mailadres"
              formControlName="email"
              (blur)="onEmailUpdate($event)"
            >
            <mat-error *ngIf="evaluationForm.controls['email'].hasError('email')">
              Vul een geldig emailadres in
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select
              placeholder="Selecteer vragenlijst"
              formControlName="survey"
              (selectionChange)="onSurveyChange()"
            >
              <mat-option *ngFor="let survey of surveys" [value]="survey">
                {{ survey.surveyName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Selecteer doelgroep" formControlName="target">
              <mat-option *ngFor="let target of selectedSurvey?.targets" [value]="target.slug">
                {{ target.targetName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngFor="let entity of surveyEntities">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="{{entity.entityName}}" formControlName="{{entity.entityName}}" (selectionChange)="onEntityChange(entity)">
              <mat-option *ngFor="let option of entity.options" [value]="option">
                {{ option.entityValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-checkbox formControlName="privacy">
            <span class="wrap">Deze informatie is anoniem beschikbaar voor het overzicht van de resultaten.</span>
          </mat-checkbox>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!evaluationForm.valid || disableSubmitBtn"
      >
        <mat-icon>fact_check</mat-icon>
        Start de vragenlijst
      </button>
    </mat-card-actions>
  </mat-card>
</form>
