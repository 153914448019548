import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/compat/firestore';
import AggregateReport from '../models/aggregate-report';
import { Timestamp } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class AggregateReportService {
  private dbPath = '/AggregateReports';
  reportsRef: AngularFirestoreCollection<AggregateReport>;

  constructor(private db: AngularFirestore) { 
    this.reportsRef = db.collection(this.dbPath);
  }

  create(report: any):Promise<DocumentReference<AggregateReport>>  {
    report.createdDate = Timestamp.fromDate(new Date());
    report.updatedDate = Timestamp.fromDate(new Date());
    return this.reportsRef.add({...report});
  }
}
