import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";
import firebase from "firebase/compat/app";

@Injectable({
    providedIn: 'root'
  })
export class AuthenticationService {
    baseURL = location.origin + '/rest/';
    constructor(private http: HttpClient,private resetService : RestService){}
    signIn(){
        this.http.get(this.baseURL+'firebase/user',{responseType:'text'})
        .subscribe((token: any)=>{
            firebase.auth().signInWithCustomToken(token)
            .then((userCredentials)=>{
                const user = userCredentials.user;
                console.log(user);
            })
            .catch((error)=>{
                const errorCode = error.code;
                const errorMessage = error.message;
            })
        })
    }

    getUser():any{

    }

    // checkUser(){
    //     firebase.auth().onAuthStateChanged(user=>{
    //         if(user){
    //             console.log("entered to check user Status");
    //             let sessionTimeout = null;
    //             user.getIdTokenResult().then((idTokenResult) => {
    //                 // Make sure all the times are in milliseconds!
    //                 const authTime = idTokenResult.claims.auth_time * 1000;
    //                 const sessionDuration = 1000 * 60 * 60;
    //                 const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
    //                 sessionTimeout = setTimeout(() => firebase.auth().signOut(), millisecondsUntilExpiration);
    //             })
    //         }
    //     })
    // }

    signOut(){
        firebase.auth().signOut().then(() => {
        // Sign-out successful.
        }).catch((error) => {
        // An error happened.
        });
    }
}
