// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const beonderConfig = {
    config: getConfig()
  };
  function getConfig() {
    let config;
    let request = new XMLHttpRequest();
    try {
      request.open('GET', 'rest/beonder/getInfo', false);  // `false` makes the request synchronous
        request.send(null);
  
        if (request.status === 200) {
            const res = JSON.parse(request.responseText)
            if(res){
              config = res;
              console.log("response is ::"+res);
            }
        }
        return config;
    } catch (e) {
        console.error('beonder:getConfig: unable to get api key : ', e);
    }
  
    return config;
  }