<mat-card>
  <mat-card-header>
    <mat-card-title>Disclaimer Riskchanger</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      In de vragenlijst publiceert Riskchanger afbeeldingen en andere materialen..
    </p>
    <h3>Auteursrecht</h3>
    <p>
      Teksten op de website mag je, tenzij anders vermeld, onder bronvermelding voor niet-commerciële doeleinden vrij
      gebruiken. Alle andere werken zoals foto’s, diagrammen of grafieken zijn beschermd. Je mag deze werken op een
      beeldscherm laden, bekijken en desgewenst afdrukken. Iedere andere openbaarmaking of verveelvoudiging al dan niet voor
      commercieel hergebruik zoals: verspreiden, verzenden, opnemen in een ander werk, netwerk of website, tijdelijke of
      permanente reproductie, vertalen of bewerken of anderszins is niet toegestaan. De ter beschikking gestelde downloads
      mogen enkel na voorafgaande schriftelijke toestemming gebruikt worden.
      
      Hoewel aan de totstandkoming van de inhoud de uiterste zorg is besteed, is het ondanks deze zorg en aandacht mogelijk
      dat de inhoud onvolledig en/of onjuist is. Riskchanger aanvaardt geen aansprakelijkheid voor eventuele fouten,
      onvolkomenheden of voor door gebruikers geplaatste content.
    </p>
    <h3>Wijzigingen</h3>
    <p>
      Riskchanger behoudt zich het recht voor zonder voorafgaande mededeling deze disclaimer te wijzigen.
    </p>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>