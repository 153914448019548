import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Firestore } from 'firebase/firestore';
import { Observable } from 'rxjs';
import Organization from '../types/organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private dbPath = '/Organizations';

  constructor(private db: AngularFirestore) {
  }

  getByDomain(domain: string): Observable<Organization[]> { 
    return this.db.collection(
      this.dbPath, ref => ref.where('organizationDomain', 'array-contains', domain).where('active', '==', 'YES')
    ).valueChanges() as unknown as Observable<any>;
  }
}