import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  baseURL = location.origin + '/rest/';

  constructor() { }

  async get (url = "") {
      try {
        const response = await fetch(this.baseURL + url);

        if (this.isResponeContentJSON(response)) {
          const json = await response.json();
          return json;
        } else {
          return response;
        }
      } catch (error) {
        throw error;
      }
    };

  async post(url: string, data = "") {
    try {
      const response = await fetch(this.baseURL + url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
        body: data,
      });

      this.handleResponseStatus(response);
      if(this.isResponeContentJSON(response)) {
        const json = await response.json();
        return json;
      } else {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  private handleResponseStatus(response: Response) {
    if (response.ok) {
      return true;
    }

    throw response;
  }

  private isResponeContentJSON(response: Response) {
    const contentType = response.headers.get("content-type");

    if (!contentType || !contentType.includes("application/json")) {
      //throw new TypeError("Oops, we haven't got JSON!");
      return false;
    }

    return true;
  }
}
