import { Injectable } from '@angular/core';
import Auth from '../types/auth';
import Organization from '../types/organization';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth: Auth | undefined = undefined;
  constructor() { }

  setEmailId(email: string): void {
    if(!this.auth) {
      this.auth = {};
    }
    this.auth.email = email;
  }

  setOrganization(org: Organization): void {
    if(!this.auth) {
      this.auth = {};
    }
    this.auth.organization = org;
  }

  getAuth(): Auth | undefined {
    return this.auth;
  }
}
