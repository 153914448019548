import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import SurveyMetadata from '../types/survey-metadata';

@Injectable({
  providedIn: 'root'
})
export class SurveyMetadataService {
  private dbPath = '/SurveyMetadata';
  constructor(private db: AngularFirestore) { }

  getBySurveyId(surveyId: string): Observable<SurveyMetadata[]> {
    return this.db.collection(
      this.dbPath, ref => ref.where('surveyId', '==', surveyId),
    ).valueChanges() as unknown as Observable<any>;
  }

  sortSurveyEntities(entities: SurveyMetadata[]): SurveyMetadata[] {
    const surveyEntities = entities.sort((a, b) => {
      return a.level - b.level;
    });
    return surveyEntities;
  }
}
