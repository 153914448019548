import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import OrganizationSurvey from '../types/organization-survey';

@Injectable({
  providedIn: 'root'
})
export class OrganizationSurveyService {
  private dbPath = '/OrganizationSurvey';

  constructor(private db: AngularFirestore) { }

  getByOrganizationId(orgId: string): Observable<OrganizationSurvey[]> {
    return this.db.collection(
      this.dbPath, ref => ref.where('organizationId', '==', orgId)
    ).valueChanges() as unknown as Observable<any>;
  }
}
