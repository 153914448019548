import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import SelfEvaluation from '../models/self-evaluation';
import { Timestamp } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class SelfEvaluationService {
  private dbPath = '/SelfEvaluation';

  selfEvalRef: AngularFirestoreCollection<SelfEvaluation>;

  constructor(private db: AngularFirestore) {
    this.selfEvalRef = db.collection(this.dbPath);
  }

  create(selfEval: SelfEvaluation): any {
    selfEval.createdDate = Timestamp.fromDate(new Date());
    selfEval.updatedDate = Timestamp.fromDate(new Date());
    return this.selfEvalRef.add({...selfEval});
  }
}
