<form [formGroup]="feedbackForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Verzamelrapport downloaden</mat-card-title>
    </mat-card-header>
    <img mat-card-image src="../../assets/images/reports.png" alt="Photo of a aggregate reports page">
    <mat-card-content>
      <p>
        Op deze pagina kun je op basis van je Riskchanger gebruikersnaam en criteria een verzamelrapport downloaden. Het rapport
        wordt per mail naar je gestuurd met alle beschikbare data van de ingevulde vragenlijsten van je eigen doelgroep.
      </p>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Jouw mailadres" formControlName="email" (blur)="onEmailUpdate($event)">
            <mat-error *ngIf="feedbackForm.controls['email'].hasError('email')">
              Vul een geldig emailadres in
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Selecteer vragenlijst" formControlName="survey" (selectionChange)="onSurveyChange()">
              <mat-option *ngFor="let survey of surveys" [value]="survey">
                {{ survey.surveyName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngFor="let entity of surveyEntities">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="{{entity.entityName}}" formControlName="{{entity.entityName}}"
              (selectionChange)="onEntityChange(entity)">
              <mat-option *ngFor="let option of entity.options" [value]="option">
                {{ option.entityValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Selecteer Doelgroep" formControlName="target" multiple>
              <mat-option *ngFor="let target of selectedSurvey?.targets" [value]="target.slug">
                {{ target.targetName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" type="submit" [disabled]="!feedbackForm.valid || disableSubmitBtn">
        <mat-icon>cloud_download</mat-icon>
        Rapporten opvragen
      </button>
    </mat-card-actions>
  </mat-card>
</form>