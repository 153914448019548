import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  getDomainName(email = ''): string {
    return email.length ? email.substring(email.lastIndexOf('@') + 1) : '';
  }
}
