import { Timestamp } from 'firebase/firestore';

export default class SelfEvaluation {
  sid?: string;
  organizationId?: string;
  surveyId?: string;
  emailid?: string;
  slug?: string;
  payload?: string;
  createdDate?: Timestamp;
  updatedDate?: Timestamp;
}