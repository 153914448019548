<mat-card>
  <mat-card-header>
    <mat-card-title>Over de 360° feedback vragenlijsten van Riskchanger</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      De vragenlijsten zijn een initiatief van Riskchanger. De content is ontwikkeld door Riskchanger, onze partners of
      klanten. Riskchanger heeft de technische ontwikkeling gerealiseerd. Met dit instrument kunnen verschillende doelgroepen
      bevraagd worden op bepaalde thema’s. Meer uitleg over de 360° feedback omgeving kunt u lezen op de Riskchanger website:
      <a href="https://www.riskchanger.nl/uitleg-360-vragenlijsten">https://www.riskchanger.nl/uitleg-360-vragenlijsten</a>.
    </p>
    <h3>Meedoen</h3>
    <p>
      Je wordt uitgenodigd voor het beantwoorden van een vragenlijst door uw onderwijsomgeving. Via jouw contactpersoon krijg
      je een QR-code met een link. Als u deze link opent of de code scant kun je de vragenlijst invullen.
    </p>
    <h3>Klachten en vragen</h3>
    <p>
      Riskchanger doet haar werk zo goed mogelijk. Toch ben je misschien niet tevreden of heb je vragen. Neem dan contact op
      via <a href="mailto: helpdesk@riskchanger.nl">helpdesk@riskchanger.nl</a>. Dat geeft ons de kans om het instrument te verbeteren.
    </p>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>
