import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/compat/firestore';
import TargetSurvey from '../models/target-survey';
import { Timestamp } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class TargetSurveyService {
  private dbPath = '/TargetSurvey';
  targetSurveyRef: AngularFirestoreCollection<TargetSurvey>;

  constructor(private db: AngularFirestore) {
    this.targetSurveyRef = db.collection(this.dbPath);
  }

  create(survey: TargetSurvey):Promise<DocumentReference<TargetSurvey>>  {
    survey.createdDate = Timestamp.fromDate(new Date());
    survey.updatedDate = Timestamp.fromDate(new Date());
    return this.targetSurveyRef.add({...survey});
  }
}
