<form [formGroup]="feedbackForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Aanmaken QR-code voor 360° vragenlijst</mat-card-title>
    </mat-card-header>
    <img mat-card-image src="../../assets/images/feedback.png" alt="Photo of a feedback page">
    <mat-card-content>
      <p>
        Genereer via deze pagina een QR-code met je Riskchanger gebruikersnaam. Op basis van je criteria ontvang je via de mail
        een QR-code die je kunt verspreiden naar de doelgroep.
      </p>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput
              placeholder="Jouw mailadres"
              formControlName="email"
              (blur)="onEmailUpdate($event)"
            >
            <mat-error *ngIf="feedbackForm.controls['email'].hasError('email')">
              Vul een geldig emailadres in
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Selecteer vragenlijst" formControlName="survey" (selectionChange)="onSurveyChange()">
              <mat-option *ngFor="let survey of surveys" [value]="survey">
                {{ survey.surveyName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngFor="let entity of surveyEntities">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="{{entity.entityName}}" formControlName="{{entity.entityName}}"
              (selectionChange)="onEntityChange(entity)">
              <mat-option *ngFor="let option of entity.options" [value]="option">
                {{ option.entityValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Selecteer Doelgroep" formControlName="target" multiple>
              <mat-option *ngFor="let target of selectedSurvey?.targets" [value]="target">
                {{ target.targetName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <mat-form-field  class="full-width">
        <mat-label>Uiterste invuldatum</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <div class="row">
        <div class="col">
          <mat-checkbox formControlName="privacy">
            <span class="wrap">Deze informatie is anoniem beschikbaar voor het overzicht van de resultaten.</span>
          </mat-checkbox>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!feedbackForm.valid || disableSubmitBtn"
      >
        <mat-icon>qr_code</mat-icon>
        Stuur link en QR Code
      </button>
    </mat-card-actions>
  </mat-card>
</form>