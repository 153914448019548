import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-over',
  templateUrl: './over.component.html',
  styleUrls: ['./over.component.scss']
})
export class OverComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
